import { useContext } from 'react';
import Head from 'next/head';
import { CookieConsent } from './CookieConsent';
import { OpenGraph } from './OpenGraph';
import { GoogleTagManager } from './GoogleTagManager';
import { SiteContext } from 'application/adapters/context/SiteContext';
import { PageContext } from 'application/adapters/context/PageContext';
import { PageContentResponse } from 'application/repositories/pageContentRepository';
import { OrttoTrackingCode } from './OrttoTrackingCode';

export const Meta: React.FC<PageContentResponse['page']['properties']> = ({
	title,
	noFollow,
	noIndex,
	description,
	canonicalUrl,
	tags,
	pageType,
}) => {
	const siteContext = useContext(SiteContext);
	const pageContext = useContext(PageContext);
	const openGraph = pageContext?.getSettings('openGraph');
	const googleTagManager = siteContext.getSettings('googleTagManager');
	const siteOrttoTrackingScript = siteContext.getSettings('siteOrttoTrackingScript')?.scriptReference?.properties;

	const locationsTag: Umbraco.Tag[] = tags?.filter((tag) => tag.url.includes('afdelinger'));
	const keywordsTags: Umbraco.Tag[] = tags?.filter((tag) => !tag.url.includes('afdelinger'));

	const noindexpage = siteContext.noIndex ? true : noIndex; 

	return (
		<>
			<Head>
				<title>{title ? title : pageContext.name}</title>
				<meta name="robots" content={`${noFollow ? 'nofollow' : 'follow'}, ${noindexpage ? 'noindex' : 'index'}`} />
				{description && <meta name="description" content={description} />}
				{canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
				{keywordsTags && <meta name="keywords" content={keywordsTags?.map((tag) => tag?.name).join('|')} />}
				{locationsTag && <meta name="locations" content={locationsTag?.map((tag) => tag?.name).join('|')} />}
				{pageType && <meta name="pageType" content={pageType} />}
				<link rel="stylesheet" href="https://customer.cludo.com/assets/3101/13264/cludo-search.min.css" type="text/css" />
			</Head>
			<CookieConsent culture={siteContext?.culture} />
			{openGraph && <OpenGraph {...openGraph} pageName={pageContext.name} pageUrl={pageContext.url} />}
			{googleTagManager && <GoogleTagManager {...googleTagManager} />}
			{siteOrttoTrackingScript && <OrttoTrackingCode {...siteOrttoTrackingScript} />}
		</>
	);
};
