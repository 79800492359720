import Head from 'next/head';
import React, { useContext, useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import { Profiles } from 'application/repositories/profilesRepository';
import { ProfileContext } from 'application/adapters/context/Profile';
export interface OrttoTrackingCodeProps {
	orttoTrackingKey : string;
	trackableDomains: string[];
}

export const OrttoTrackingCode: React.FC<OrttoTrackingCodeProps> = (props) => {
	if (!props.orttoTrackingKey) return;

	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const { status } = useSession();
	const [profiles, setProfiles] = useState<Profiles>(null);
	useEffect(() => {
		if (status === 'authenticated') {
			setIsLoggedIn(true);
		} else {
			setIsLoggedIn(false);
		}
	}, [status]);


	const profileContext = useContext(ProfileContext);

	const profile = profileContext.profile
	useEffect(() => {
		if (profile) {
			setProfiles(profile);
		}
	}, [profile]);

	const trackingCode = 
	`
    window.ap3c = window.ap3c || {};
    var ap3c = window.ap3c;
    ap3c.cmd = ap3c.cmd || [];
    ap3c.cmd.push(function() {
        ap3c.init('${props.orttoTrackingKey}', 'https://capture-api-eu.ortto.app/' ${
            props.trackableDomains && props.trackableDomains.length > 0 
                ? `, {cookieDomains: ['${props.trackableDomains.join("', '")}']}` 
                : ''
        });
        ap3c.track({ ac: [{ 'fi': 'str:cm:membership-id', 'v':'${ profile?.membership?.id}' }]});
    });
    ap3c.activity = function(act) { ap3c.act = (ap3c.act || []); ap3c.act.push(act); };
    var s, t; s = document.createElement('script'); s.type = 'text/javascript'; s.src = 'https://cdneu.net/app.js';
    t = document.getElementsByTagName('script')[0]; t.parentNode.insertBefore(s, t);
	`


	return (
		<>
		{isLoggedIn &&  profile?.membership?.id &&
			<Head>
				{/*<!-- Google Tag Manager --> */}
				<script dangerouslySetInnerHTML={{ __html: trackingCode }} />
				{/*<!-- End Google Tag Manager --> */}
			</Head>
		}

		</>
	);
};
